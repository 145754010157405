import axiosInstance from '@/plugins/axios'

export default {
  getIndividualAnalysis(accountId) {
    const url = `admin/individual-analysis/${accountId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getHedgingAnalysis(accountId) {
    const url = `admin/hedging-analysis/${accountId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },
}
